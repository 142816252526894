<hr class="filter-hr">
<a class="a-link" style="float: right;" href="javascript:void(0)" (click)="requestFilter()">Apply</a>
<div class="dynamic-filter">
    <ul class="list-unstyled ps-0 ">
        <li class="mb-1 main-list">
            <button class="btn dropdown-toggle align-items-center rounded filter-head"
              data-bs-toggle="collapse"
              data-bs-target="#dynamicFilter"
              aria-expanded="false">Dynamic filters
            </button>
            <div class="collapse sub-list show" id="dynamicFilter">
                <ul class="btn-toggle-nav list-unstyled sub-filter">
                  @for (item of items; track $index) {
                    <li class="mb-1">
                      <button class="btn dropdown-toggle align-items-center rounded"
                        [attr.aria-expanded]="item.isExpanded"
                        (click)="item.isExpanded = !item.isExpanded"
                        [attr.data-bs-target]="'#collapse' + $index"
                        data-bs-toggle="collapse">
                         {{item.name | translate}}
                         @if (item.selectedCount > 0) {
                          ({{item.selectedCount}})
                         }
                      </button>
                      <div  [id]="'collapse' + $index"
                            class="collapse"
                            [class.show]="item.isExpanded">
                        <ul class="btn-toggle-nav list-unstyled sub-menus ps-2">
                          @for (option of item.options; track $index) {
                            <li (click)="onCheckboxChange(item,option)">
                              <div class="form-check">
                                <input class="form-check-input"
                                  type="checkbox"
                                  [value]="option.id"
                                  [id]="option.label"
                                  [(ngModel)]="option.isSelected"/>
                                  <label class="form-check-label">
                                      {{option.label | translate}}
                                  </label>
                                </div>
                            </li>
                          }
                        </ul>
                      </div>
                    </li>
                  }
                </ul>
            </div>
        </li>
    </ul>
</div>
