import { Component, EventEmitter, Input, Output, input } from '@angular/core';
import {
  FilterCategory,
  FilterOption,
} from '../../../../core/modals/table.modal';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-dynamic-filters',
  standalone: true,
  imports: [TranslateModule, FormsModule],
  templateUrl: './dynamic-filters.component.html',
  styleUrl: './dynamic-filters.component.scss',
})
export class DynamicFiltersComponent {
  @Input() items: FilterCategory[];

  @Output() filterRequest = new EventEmitter();

  onCheckboxChange(category: FilterCategory, option: FilterOption): void {
    option.isSelected = !option.isSelected;

    if (option.isSelected) {
      category.selectedCount++;
    } else {
      category.selectedCount--;
    }
  }

  requestFilter() {
    this.filterRequest.emit();
  }
}
